@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&family=Syne&display=swap");

@layer base {
  *:not("svg") {
    font-family: Inter;
    @apply font-inter;
    @apply text-dark-900;
  }

  #root {
    @apply h-screen;
    @apply w-screen;
    @apply font-inter;
  }

  #positions {
    background-image: linear-gradient(to bottom, #edf0f7 20%, #0f1829);
  }

  h1 {
    @apply text-4xl;
    @apply font-bold;
    @apply font-syne;
  }

  body {
    @apply font-inter;
    background: #edf0f7;
  }

  h2 {
    @apply text-[2.5rem];
    @apply leading-[2.5rem];
    @apply font-bold;
    @apply font-syne;
  }

  h3 {
    @apply text-[28px];
    @apply font-bold;
    @apply font-syne;
  }

  h4 {
    @apply text-xl;
    @apply font-bold;
    @apply font-syne;
  }
  h5 {
    @apply text-lg;
    @apply font-bold;
  }
  h6 {
    @apply text-base;
    @apply font-bold;
  }

  p,
  a,
  label,
  span,
  div,
  li {
    @apply text-sm;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  #page-not-found {
    background-image: url("/public/Lines.png");
  }
}
